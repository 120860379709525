import {
  Dashboard,
  Task,
  Assignment,
  Settings,
} from '@mui/icons-material';

export const DRAWER_WIDTH = 240;

export const MENU_ITEMS = [
  { text: 'Dashboard', icon: Dashboard, path: '/' },
  { text: 'Tasks', icon: Task, path: '/tasks' },
  { text: 'Workflows', icon: Assignment, path: '/workflows' },
  { text: 'Settings', icon: Settings, path: '/settings' },
] as const; 